<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      back
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <agenda-legend
        :showing.sync="showingDialogs.legends"
        :tipos-de-actuacion-legend="tiposDeActuacionLegend"
        name="AgendaLegend"
      />
      <v-alert
        v-show="sinFechaFijada"
        type="warning"
        :value="true"
        class="ma-2"
        dismissible
      >
        <v-row align="center">
          <v-col
            class="grow pa-0"
          >
            Tienes asignados {{ sinFechaFijada }} {{ 'parte'|pluralize(sinFechaFijada, 's') }} de trabajo
            sin fecha fijada ({{ 'creado'|pluralize(sinFechaFijada, 's') }} durante {{ mesAnio }})
          </v-col>
          <v-col class="shrink">
            <v-btn
              text
              @click="clickVerSinFechaFijada"
            >
              Ver
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-sheet
        class="ma-2"
        :height="700"
      >
        <v-calendar
          ref="calendar"
          v-model="fecha"
          :type="type"
          :events="formattedEventos"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="changeCalendar"
          @click:event="clickEvento"
          @click:date="clickDay"
          @click:more="clickDay"
        >
          <template #event="{ event }">
            <div class="pl-1 v-event-summary">
              <strong :style="{color: event.textColor}">{{ event.name }}</strong><br>
              {{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}
            </div>
          </template>
          <template #day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            />
          </template>
        </v-calendar>
      </v-sheet>
      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="$refs.calendar.prev()"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="$refs.calendar.next()"
      />
    </b10-page-content>
    <v-dialog
      v-model="showingDialogs.evento"
    >
      <v-card
        v-if="selectedEvento"
      >
        <v-card-title>
          {{ selectedEvento.name }}
        </v-card-title>
        <v-card-subtitle>
          {{ selectedEvento.subtitle }}
        </v-card-subtitle>
      </v-card>
    </v-dialog>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './AgendaData'
import AgendaLegend from './components/AgendaLegend'
import { firstDayOfMonth, addMonths, format as formatDate, currentDate, toDate, toDateTime, addSeconds } from '@/utils/date'
import { get, sync } from 'vuex-pathify'
import { CITA, OPERACIONCRM, TACTUACION, AGENDA } from '@/utils/consts'
import { fontColor } from '@/utils/ui'
import _ from '@/utils/lodash'
import { encodeBase64 } from '@/utils/router'

const pageStoreName = 'pagesAgenda'

export default {
  components: {
    AgendaLegend
  },
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    if (!this.beforeRouteLeaveBase(to, from, next)) {
      if (!this.rememberState) {
        this.fecha = null
      }
      next()
    }
  },
  data () {
    return {
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
        addCita: {
          title: 'Añadir cita',
          visible: true,
          icon: 'add',
        },
        addOperacionCRM: {
          title: 'Añadir operación',
          visible: true,
          icon: 'add',
        },
        irAHoy: {
          title: 'Ir a hoy',
          visible: true,
          icon: 'irAHoy',
        },
        verAgendaDia: {
          title: 'Ver agenda diaria',
          visible: true,
          icon: 'verAgendaDia',
        },
        verAgendaSemana: {
          title: 'Ver agenda semanal',
          visible: true,
          icon: 'verAgendaSemana',
        },
        verAgendaMes: {
          title: 'Ver agenda mensual',
          visible: true,
          icon: 'verAgendaMes',
        }

      },
      showingDialogs: {
        evento: false,
        legends: false,
      },
      selectedEvento: null,
      CITA,
      OPERACIONCRM,
      TACTUACION,
      AGENDA,
      ready: false,
      format24h: true,
      tipoEvento: {
        cita: 1,
        operacioncrm: 2,
        parteTrabajo: 3,
      },
      inIdsPartesOffline: [],
    }
  },
  computed: {
    fecha: sync(`${pageStoreName}/fecha`),
    eventos: sync(`${pageStoreName}/eventos`),
    type: sync(`${pageStoreName}/type`),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdempleado: get('usuario/idempleado'),
    appComerciales: get('usuario/appComerciales'),
    mesAnio () {
      return this.fecha && formatDate(toDate(this.fecha), 'MMMM yyyy')
    },
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    tiposDeActuacionLegend () {
      let tiposDeActuacionLegend = []
      if (this.eventos?.selectParteTrabajo) {
        let titulo
        let color
        for (const evento of this.eventos.selectParteTrabajo.result.dataset) {
          titulo = `${TACTUACION.descripciones[evento.tactuacion_tipo]} - ${evento.tsistema_desc_corta}`
          color = evento.tactuacion_tsistema_color
          const tiposDeActuacionLegendExiste = _.find(
            tiposDeActuacionLegend,
            {
              color: color,
              descripcion: titulo,
            }
          )
          if (!tiposDeActuacionLegendExiste) {
            tiposDeActuacionLegend.push({
              color: color,
              descripcion: titulo,
            })
          }
        }
      }
      return tiposDeActuacionLegend
    },
    formattedEventos () {
      const eventos = []
      let allDay
      let fechaFin
      let color
      // Citas
      if (this.eventos?.selectCita && this.hasViewPerm(this.permissions.citas.id)) {
        for (const evento of this.eventos.selectCita.result.dataset) {
          allDay = this.$options.filters.shortDate(evento.fcita) === this.$options.filters.shortDate(evento.ffin)
          color = this.CITA.coloresEstado[evento.estado]
          eventos.push({
            id: evento.idcita,
            name: evento.titulo,
            subtitle: evento.cita,
            start: evento.fcita,
            end: evento.ffin,
            timed: allDay,
            color: color,
            tipoEvento: this.tipoEvento.cita,
            textColor: fontColor(color),
          })
        }
      }
      // CRM
      if (this.appComerciales && this.eventos?.selectOperacionCRM && this.hasViewPerm(this.permissions.operaciones.id)) {
        for (const evento of this.eventos.selectOperacionCRM.result.dataset) {
          color = this.OPERACIONCRM.coloresPrioridad[evento.idprioridad]
          eventos.push({
            id: evento.idoperacioncrm,
            name: this.$online.operacioncrm.name(evento),
            subtitle: this.$online.operacioncrm.title(evento),
            start: evento.fproximarevision,
            end: null,
            timed: true,
            color: color,
            tipoEvento: this.tipoEvento.operacioncrm,
            textColor: fontColor(color),
          })
        }
      }
      // Partes
      if (this.eventos?.selectParteTrabajo && this.hasViewPerm(this.permissions.partes.verAsignadosAMi)) {
        for (const evento of this.eventos.selectParteTrabajo.result.dataset) {
          if (evento.fijado) {
            const fechaInicio = evento.ffijada
            if (evento.fprevista_fin) {
              fechaFin = evento.fprevista_fin
            } else {
              fechaFin = addSeconds(fechaInicio, AGENDA.segundosExtra)
            }
            // mostrar color beta si está en blanco
            color = evento.tactuacion_tsistema_color
            eventos.push({
              id: evento.idparte_trabajo,
              name: `${TACTUACION.descripciones[evento.tactuacion_tipo]} - ${evento.tsistema_desc_corta}`,
              subtitle: evento.tsistema_desc_corta,
              start: fechaInicio,
              end: fechaFin,
              timed: true,
              color: color,
              tipoEvento: this.tipoEvento.parteTrabajo,
              textColor: fontColor(color),
            })
          }
        }
      }
      return eventos
    },
    sinFechaFijada () {
      let toReturn = 0
      if (this.eventos?.selectParteTrabajo) {
        for (const evento of this.eventos.selectParteTrabajo.result.dataset) {
          if (!evento.fijado) {
            toReturn++
          }
        }
      }
      return toReturn
    },
  },
  watch:{
    // https://github.com/vuetifyjs/vuetify/issues/12473
    type: {
      handler(new_type, prev_type){
        if (prev_type == "month"){
          this.$nextTick(() => {
            this.scrollToTime()
          })
        }
      }
    }
  },
  mounted () {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)
      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    setToday () {
      this.focus = ''
      this.fecha = currentDate()
    },
    async loadPage () {
      this.loadOfflineStatus()
      if (!this.fecha) {
        this.fecha = currentDate()
      }
      this.toolbarOptions.addOperacionCRM.visible = this.appComerciales && this.hasInsertPerm(this.permissions.operaciones.id)
      this.toolbarOptions.addCita.visible = this.hasInsertPerm(this.permissions.citas.id)
      const resp = await Data.selectAgenda(this, this.usuarioIdempleado, toDate(this.fecha), this.usuarioIdtecnico)
      this.eventos = resp.data
    },
    async loadOfflineStatus () {
      this.inIdsPartesOffline = await this.$offline.parteTrabajo.inIdsPartesOffline()
    },
    async changeCalendar () {
      await this.loadPage()
      this.title = `Agenda ${this.mesAnio}`
    },
    clickEvento (evento) {
      this.rememberState = true
      this.selectedEvento = evento.event
      switch (evento.event.tipoEvento) {
      case this.tipoEvento.cita:
        this.$appRouter.push({
          name: 'citas__cita-view',
          params: {
            idcita: evento.event.id,
          },
        })
        break
      case this.tipoEvento.operacioncrm :
        this.$appRouter.push({
          name: 'operacionescrm__operacioncrm-view',
          params: {
            idoperacioncrm: evento.event.id,
          },
        })
        break
      case this.tipoEvento.parteTrabajo:
        if (this.inIdsPartesOffline.indexOf(evento.event.id) >= 0) {
          this.rememberState = true
          this.rememberScroll = true
          this.$appRouter.push({
            name: 'offline__parte-trabajo-view',
            params: {
              idparte_trabajo: evento.event.id
            }
          })
        } else {
          this.rememberState = true
          this.rememberScroll = true
          this.$appRouter.push({
            name: 'partes__parte-trabajo-view',
            params: {
              idparte_trabajo: evento.event.id
            }
          })
        }
        break
      default:
        console.error(`La opción ${evento.event.tipoEvento} no está contemplada`);
      }
    },
    clickDay (day) {
      this.type = this.AGENDA.tipo.diario
      this.fecha = day.date
    },
    formatEventTime(date) {
      return this.$options.filters.shortTime(date)
    },
    clickToolbarOption (option) {
      switch (option) {
      case this.toolbarOptions.legend:
        this.showingDialogs.legends = true
        break
      case this.toolbarOptions.addCita:
        this.$appRouter.push({
          name: 'citas__cita-add',
          query: {
            fecha: toDateTime(this.fecha),
          },
        })
        break
      case this.toolbarOptions.addOperacionCRM:
        this.$appRouter.push({
          name: 'operacionescrm__operacioncrm-add',
          query: {
            fecha: toDateTime(this.fecha),
          },
        })
        break
      case this.toolbarOptions.irAHoy:
        this.setToday()
        break
      case this.toolbarOptions.verAgendaDia:
        this.type = this.AGENDA.tipo.diario
        break
      case this.toolbarOptions.verAgendaSemana:
        this.type = this.AGENDA.tipo.semanal
        break
      case this.toolbarOptions.verAgendaMes:
        this.type = this.AGENDA.tipo.mensual
        break
      default:
        console.error(`La opción ${option} no está contemplada`);
      }
    },
    clickVerSinFechaFijada () {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'partes__parte-asignados-list',
        query: {
          _filter: encodeBase64({
            conFfijada: {
              value: false,
              options: { fixed: true },
            },
            fdesde: {
              value: firstDayOfMonth(this.fecha),
              options: { fixed: true },
            },
            fhasta: {
              value: addMonths(firstDayOfMonth(this.fecha), 1),
              options: { fixed: true },
            },
            tecnico: {
              value: this.usuarioIdtecnico,
              options: { fixed: true },
            },
          }),
        },
      })
    },
  }
}
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>